<template>
  <div ref="comboType" class="comboType">
    <div>
      <a-button
        style="width: 85px; margin-right: 20px"
        type="primary"
        @click="handleOperate('add')"
        >新增
      </a-button>
      <a-button style="width: 85px" type="primary" @click="$router.go(-1)"
        >返回
      </a-button>
    </div>
    <a-table
      :columns="columns"
      :dataSource="dataSource"
      :loading="loading"
      :pagination="position"
      :row-class-name="
        (_record, index) => (index !== 1 ? 'table-content' : null)
      "
      bordered
      class="mt30"
      @change="tableChange"
    >
      <template #bodyCell="{ column, text, record }">
        <template v-if="column.dataIndex === 'pictures'">
          <img :src="text" alt="" style="max-width: 100px; max-height: 100px" />
        </template>
        <template v-if="column.dataIndex === 'action'">
          <a-button
            style="margin-right: 20px"
            type="primary"
            @click="edit('edit', record.id)"
            >编辑
          </a-button>
          <a-button danger type="primary" @click="del(record.id)"
            >删除
          </a-button>
        </template>
      </template>
    </a-table>
    <a-modal
      v-model:visible="visible"
      :footer="null"
      :getContainer="$refs.comboType"
      :title="modalType[operate].title"
      centered
      width="60%"
    >
      <a-divider orientation="left" style="font-size: 20px">基本信息</a-divider>
      <a-form
        :label-col="{ span: 5 }"
        :model="formState"
        :wrapper-col="{ span: 19 }"
        autocomplete="off"
        name="basic"
        @finish="onFinish"
      >
        <a-form-item
          :rules="[{ required: true, message: '必填选项不能为空' }]"
          :wrapper-col="{ span: 8 }"
          label="套餐类型名称"
          name="name"
        >
          <a-input v-model:value="formState.name" />
        </a-form-item>
        <a-form-item
          :wrapper-col="{ span: 8 }"
          label="套餐类型缩写"
          name="prefix"
        >
          <a-input v-model:value="formState.prefix" />
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 8 }" label="图片" name="pictures">
          <a-upload
            v-model:file-list="fileList"
            :before-upload="beforeUpload"
            :maxCount="1"
            :show-upload-list="false"
            class="avatar-uploader"
            list-type="picture-card"
            name="file"
          >
            <img
              v-if="formState.pictures"
              :src="formState.pictures"
              alt="封面"
              style="max-width: 102px; max-height: 102px"
            />
            <div v-else>
              <plus-outlined></plus-outlined>
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
          <a-button html-type="submit" type="primary">保存</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
//分页配置
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons-vue'
import {
  productType,
  productTypeAdd,
  productTypeDel,
  productTypeDetails,
  productTypeEdit
} from '@/api'
import { createVNode, onMounted, reactive, ref } from 'vue'
import { message, Modal } from 'ant-design-vue'

onMounted(() => {
  getProductType()
})
const loading = ref(false)
//分页配置
let position = reactive({
  current: 1, //当前页数
  pageSize: 10, //每页条数
  showSizeChanger: true, //是否可以改变 pageSize
  showQuickJumper: true, //是否可以快速跳转至某页
  pageSizeOptions: ['10', '20', '30', '100'], //	指定每页可以显示多少条
  total: 0, //数据总数
  showTotal: (total) => `共${total}条`, //用于显示数据总量和当前数据顺序
  position: ['bottomLeft'] //分页器位置
})
//分页数据
const positionData = reactive({
  pageIndex: 1,
  pageSize: 10,
  isValid: true
})
//表格数据
const dataSource = ref([])
//表格配置
const columns = [
  {
    title: '套餐类型名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center'
  },
  {
    title: '套餐类型缩写',
    dataIndex: 'prefix',
    key: 'prefix',
    align: 'center'
  },
  {
    title: '图片',
    dataIndex: 'pictures',
    key: 'pictures',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'id',
    width: 300
  }
]
const getProductType = (pagination) => {
  if (pagination) {
    positionData.pageIndex = pagination.current
    positionData.pageSize = pagination.pageSize
  }
  productType(positionData)
    .then((res) => {
      position.total = res.data.count
      dataSource.value = res.data.data
      if (pagination) {
        position.current = pagination.current
        position.pageSize = pagination.pageSize
      }
    })
    .catch((error) => {
      Modal.error({
        content: error.response.data
      })
    })
}
const tableChange = (pagination) => {
  getProductType(pagination)
}

const visible = ref(false)
const operate = ref('add')
const modalType = {
  add: {
    title: '新增套餐类型'
  },
  edit: {
    title: '编辑套餐类型'
  }
}
const handleOperate = (type) => {
  formState.value = {
    name: '', //套餐名称
    prefix: '', //套餐缩写
    pictures: '' //图片二进制
  }
  operate.value = type
  visible.value = true
}
const formState = ref({
  name: '', //套餐名称
  prefix: '', //套餐缩写
  pictures: '' //图片二进制
})

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const fileList = ref([])
//上传前图片类型判断
const beforeUpload = (file) => {
  const isJpgOrPng =
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/gif' ||
    file.type === 'image/jpg'

  if (!isJpgOrPng) {
    message.error('只支持上传jpeg、jpg、png、gif!')
    return false
  }
  const isLt5M = file.size / 1024 / 1024 < 5
  if (!isLt5M) {
    message.error('图片大小必须小于5MB!')
    return false
  }
  fileList.value = [...fileList.value, file]
  getBase64(file, (base64Url) => {
    formState.value.pictures = base64Url
  })
  return false
}
const onFinish = (values) => {
  Modal.confirm({
    title: '确认保存修改信息?',
    icon: createVNode(ExclamationCircleOutlined),
    centered: true,
    onOk() {
      const formData = new FormData()
      formData.append('file', fileList.value[0].originFileObj)
      formData.append('name', values.name)
      formData.append('prefix', values.prefix)
      if (operate.value === 'add') {
        productTypeAdd(formData)
          .then(() => {
            message.success('操作成功')
            visible.value = false
            location.reload()
          })
          .catch((error) =>
            Modal.error({
              content: error.response.data
            })
          )
      } else {
        productTypeEdit(Id.value, formData)
          .then(() => {
            message.success('操作成功')
            visible.value = false
            location.reload()
          })
          .catch((error) =>
            Modal.error({
              content: error.response.data
            })
          )
      }
    },
    onCancel() {}
  })
}
//删除
const del = (id) => {
  Modal.confirm({
    title: '确定删除当前信息?',
    icon: createVNode(ExclamationCircleOutlined),
    centered: true,
    onOk() {
      productTypeDel(id)
        .then(() => {
          message.success('该类型没有产品使用，删除成功')
          getProductType()
        })
        .catch((error) => {
          Modal.error({
            content: error.response.data
          })
          getProductType()
        })
    },
    onCancel() {}
  })
}
//编辑
const Id = ref('')
const edit = (type, id) => {
  productTypeDetails(id)
    .then((res) => {
      operate.value = type
      visible.value = true
      formState.value = res.data
      Id.value = id
    })
    .catch((error) =>
      Modal.error({
        content: error.response.data
      })
    )
}
</script>

<style scoped>
.mt30 {
  margin-top: 30px;
}

:deep(.table-content) .ant-table-cell {
  padding: 8px 16px !important;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
